import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsWhatsapp, BsTwitterX, BsFacebook } from "react-icons/bs";

import { TbClover } from "react-icons/tb";

import './MegaSenaCard.css';

const MegaSenaCard = () => {
  const [resultado, setResultado] = useState({});
  const [concursoPesquisado, setConcursoPesquisado] = useState('');
  const [todosResultados, setTodosResultados] = useState([]);
  const [frequencias, setFrequencias] = useState({});
  const [listarTodosClicado, setListarTodosClicado] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchResultado = async (concurso) => {
    try {
      setLoading(true);
      const response = await axios.get(`https://loteriascaixa-api.herokuapp.com/api/megasena/${concurso}`);
      setResultado(response.data);
    } catch (error) {
      console.error(`Erro ao buscar o resultado do concurso ${concurso}`, error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTodosResultados = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://loteriascaixa-api.herokuapp.com/api/megasena');
      setTodosResultados(response.data);

      const novaFrequencia = response.data.reduce((acc, resultado) => {
        resultado.dezenas.forEach((numero) => {
          acc[numero] = (acc[numero] || 0) + 1;
        });
        return acc;
      }, {});

      setFrequencias(novaFrequencia);
    } catch (error) {
      console.error('Erro ao buscar todos os resultados da Mega-Sena', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUltimoResultado = async () => {
    try {
      const response = await axios.get('https://loteriascaixa-api.herokuapp.com/api/megasena/latest');
      setResultado(response.data);
    } catch (error) {
      console.error('Erro ao buscar o último resultado da Mega-Sena', error);
    }
  };

  useEffect(() => {
    if (listarTodosClicado) {
      fetchTodosResultados();
      setListarTodosClicado(false);
    } else {
      fetchUltimoResultado();
    }
  }, [listarTodosClicado]);

  const formatarValorAbreviado = (valor) => {
    const milhao = 1000000;
    const bilhao = 1000000000;

    if (valor === undefined) {
      return 'N/A';
    }

    if (valor >= bilhao) {
      const bilhoes = (valor / bilhao).toFixed(2);
      const valorFormatado = (bilhoes * bilhao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      return valorFormatado.replace('R$', '') + '';
    } else if (valor >= milhao) {
      const milhoes = (valor / milhao).toFixed(2);
      const valorFormatado = (milhoes * milhao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      return valorFormatado.replace('R$', '') + ' ';
    } else {
      const valorFormatado = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      return valorFormatado.replace('R$', '');
    }
  };

  const calcularSomaDosNumeros = (numeros) => {
    return numeros.reduce((soma, numero) => soma + parseInt(numero, 10), 0);
  };

  const exibirGanhadores = (localGanhadores) => {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Município</th>
              <th>UF</th>
              <th>Quantidade</th>
            </tr>
          </thead>
          <tbody>
            {localGanhadores.map((ganhador, index) => (
              <tr key={index}>
                <td>{ganhador.municipio}</td>
                <td>{ganhador.uf}</td>
                <td>{ganhador.ganhadores}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const exibirInformacoesExtras = (resultado) => {
    const ganhadoresFaixa1 = resultado?.premiacoes?.find(premiacao => premiacao.faixa === 1)?.ganhadores || 0;
    const somaDosNumeros = resultado?.dezenas ? calcularSomaDosNumeros(resultado.dezenas) : 0;
    let pares = 0;
    let impares = 0;

    resultado?.dezenas?.forEach(numero => {
      if (parseInt(numero, 10) % 2 === 0) {
        pares++;
      } else {
        impares++;
      }
    });

    const localGanhadores = resultado?.localGanhadores || [];

    return (
      <div>
        <p>Soma: {somaDosNumeros} | Pares: {pares} | Ímpares: {impares}</p>

        <h3>
          <strong>
            {resultado?.acumulou
              ? 'PRÊMIO ACUMULADO'
              : ganhadoresFaixa1 === 1
                ? `${ganhadoresFaixa1} GANHADOR `
                : `${ganhadoresFaixa1} GANHADORES`
            }
          </strong>
        </h3>
        <p>{localGanhadores.length > 0 && exibirGanhadores(localGanhadores)}</p>
        {resultado?.acumulou && (
          <h3>R${formatarValorAbreviado(resultado.valorEstimadoProximoConcurso)}</h3>
        )}
        <table>
          <thead>
            <tr>
              <th>Premiação</th>
              <th>Ganhadores</th>
              <th>Valor do Prêmio</th>
            </tr>
          </thead>
          <tbody>
            {resultado?.premiacoes &&
              resultado.premiacoes.map((premiacao) => (
                <tr key={premiacao.faixa}>
                  <td>{premiacao.descricao}</td>
                  <td>{premiacao.ganhadores}</td>
                  <td>R${formatarValorAbreviado(premiacao.valorPremio.toFixed(2))}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  const handlePesquisar = () => {
    if (!isNaN(concursoPesquisado) && concursoPesquisado.trim() !== '') {
      fetchResultado(concursoPesquisado);
    } else {
      console.error('Número do concurso inválido');
    }
  };

  const handleListarTodos = () => {
    setResultado({});
    setListarTodosClicado(true);
  };

  const handleCompartilharFacebook = () => {
  const mensagem = `Galera!%0A%0AO resultado da Mega Sena saiu!%0A%0AConfira os números sorteados:%0A%0AConcurso ${resultado.concurso}%0A%0A${resultado.dezenas.join(' - ')}.%0A%0ABoa sorte a todos! 🍀🎉%0A`;
  window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&quote=${mensagem}`, '_blank');
};

  const handleCompartilharTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?text=Checkout%20este%20resultado%20da%20Mega%20Sena&url=${encodeURIComponent(window.location.href)}`, '_blank');
  };

  const handleCompartilharWhatsapp = () => {
  const mensagem = `Galera! \n\nO resultado da Mega Sena saiu!\n\nConfira os números sorteados: \n\nConcurso ${resultado.concurso}\n\n ${resultado.dezenas.join(' - ')}.\n\nBoa sorte a todos! 🍀🎉\n`;
  window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(mensagem)}${encodeURIComponent(window.location.href)}`, '_blank');
};

  return (
    <div className="megasena-card">
      <h1>MEGA-SENA CONCURSO {resultado?.concurso || 'N/A'} ({resultado?.data || 'N/A'})</h1>
      <div className="bolas-container">
        {resultado?.dezenas && resultado.dezenas.map((numero, index) => (
          <div key={index} className="bola-mega">
            {numero}
          </div>
        ))}
      </div>
      <p></p>

      {exibirInformacoesExtras(resultado)}

      <div className="navegacao">
        <input
          type="text"
          placeholder="Número do concurso"
          value={concursoPesquisado}
          onChange={(e) => setConcursoPesquisado(e.target.value)}
        />
        <button onClick={handlePesquisar}>Pesquisar</button>
        <button onClick={handleListarTodos}>Listar Todos</button>
      </div>

      {loading && <h2><TbClover className="rotating-icon" /> Carregando resultados...<TbClover className="rotating-icon" /></h2>}

      <div className="compartilhamento">
        <button onClick={handleCompartilharWhatsapp} className="icone-botao"><BsWhatsapp size={20} /></button>
        <button onClick={handleCompartilharFacebook} className="icone-botao"><BsFacebook size={20} /></button>
        <button onClick={handleCompartilharTwitter} className="icone-botao"><BsTwitterX size={20} /></button>
      </div>

      {todosResultados && todosResultados.length > 0 && (
        <div className="todos-resultados">
          <h1>TODOS OS RESULTADOS DA MEGA-SENA</h1>
          {todosResultados.map((resultado, index) => (
            <div key={index} className="resultado-container">
              <h2><strong>CONCURSO</strong> {resultado?.concurso || 'N/A'} ({resultado?.data || 'N/A'})</h2>
              <div className="bolas-container">
                {resultado?.dezenas && resultado.dezenas.map((numero, index) => (
                  <div key={index} className="bola-mega">
                    {numero}
                  </div>
                ))}
              </div>
              {exibirInformacoesExtras(resultado)}
              {index < todosResultados.length - 1 && <hr className="linha-tracada" />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MegaSenaCard;
