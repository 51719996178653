import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaFacebook, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import './QuinaCard.css';

const QuinaCard = () => {
  const [resultado, setResultado] = useState({});
  const [concursoPesquisado, setConcursoPesquisado] = useState('');
  const [todosResultados, setTodosResultados] = useState([]);
  const [listarTodosClicado, setListarTodosClicado] = useState(false);

  const fetchResultado = async (concurso) => {
    try {
      const response = await axios.get(`https://loteriascaixa-api.herokuapp.com/api/quina/${concurso}`);
      setResultado(response.data);
    } catch (error) {
      console.error(`Erro ao buscar o resultado do concurso ${concurso}`, error);
    }
  };

  const fetchTodosResultados = async () => {
    try {
      const response = await axios.get('https://loteriascaixa-api.herokuapp.com/api/quina');
      setTodosResultados(response.data);
    } catch (error) {
      console.error('Erro ao buscar todos os resultados da Quina', error);
    }
  };

  const fetchUltimoResultado = async () => {
    try {
      const response = await axios.get('https://loteriascaixa-api.herokuapp.com/api/quina/latest');
      setResultado(response.data);
    } catch (error) {
      console.error('Erro ao buscar o último resultado da Quina', error);
    }
  };

  useEffect(() => {
    if (listarTodosClicado) {
      fetchTodosResultados();
      setListarTodosClicado(false); // Resetar o estado após carregar os resultados
    } else {
      fetchUltimoResultado();
    }
  }, [listarTodosClicado]);

  const handlePesquisar = () => {
    // Verificar se o número do concurso é numérico e não está vazio
    if (!isNaN(concursoPesquisado) && concursoPesquisado.trim() !== '') {
      fetchResultado(concursoPesquisado);
    } else {
      // Exibir mensagem de erro ou tomar outra ação apropriada
      console.error('Número do concurso inválido');
    }
  };

  const handleListarTodos = () => {
    setResultado({}); // Limpar o resultado atual
    setListarTodosClicado(true); // Sinalizar que o botão "Listar Todos" foi clicado
  };

  const formatarValorAbreviado = (valor) => {
    const milhao = 1000000;
    const bilhao = 1000000000;

    if (valor >= bilhao) {
      return `R$${(valor / bilhao).toFixed(0)} bilhões`;
    } else if (valor >= milhao) {
      return `R$${(valor / milhao).toFixed(0)} milhões`;
    } else {
      return `R$${Math.round(valor)}`;
    }
  };

  const exibirInformacoesExtras = (resultado) => {
    if (resultado.acumulou) {
      return (
        <div>
          <p><strong>PRÊMIO ACUMULADO</strong></p>
          <p><strong>{formatarValorAbreviado(resultado.valorEstimadoProximoConcurso)}</strong></p>
        </div>
      );
    } else if (resultado.premiacoes && resultado.premiacoes.find(premiacao => premiacao.faixa === 1)) {
      const ganhadores = resultado.premiacoes.find(premiacao => premiacao.faixa === 1).ganhadores;
      const ganhadoresInfo = resultado.localGanhadores.map(ganhador => (
        <p keya={ganhador.uf}>{ganhador.municipio}, {ganhador.uf},  {ganhador.ganhadores}</p>
      ));

      return (
        <div>
          <p><strong> {ganhadores}{ganhadores > 1 ? ' GANHADORES' : ' GANHADOR'}</strong></p>
          {ganhadoresInfo}
        </div>
      );
    } else {
      return null; // Não mostrar nada se não houver ganhadores na faixa 1
    }
  };

  const handleCompartilharFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, '_blank');
  };

  const handleCompartilharTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?text=Checkout%20este%20resultado%20da%20Mega%20Sena&url=${encodeURIComponent(window.location.href)}`, '_blank');
  };

  const handleCompartilharWhatsapp = () => {
    window.open(`https://api.whatsapp.com/send?text=Checkout%20este%20resultado%20da%20Mega%20Sena:%20${encodeURIComponent(window.location.href)}`, '_blank');
  };

  return (
    <div className="lotofacil-card">
      <h2>Último resultado da Quina</h2>
      <p><strong>Concurso:</strong> {resultado.concurso || 'N/A'}</p>
      <div className="bolas-container">
        {resultado.dezenas && resultado.dezenas.map((numero, index) => (
          <div key={index} className="bola-quina">
            {numero}
          </div>
        ))}
      </div>
      <p><strong>Data do Sorteio:</strong> {resultado.data || 'N/A'}</p>
      {exibirInformacoesExtras(resultado)}
      <div className="navegacao">
        <input
          type="text"
          placeholder="Número do concurso"
          value={concursoPesquisado}
          onChange={(e) => setConcursoPesquisado(e.target.value)}
        />
        <button onClick={handlePesquisar}>Pesquisar</button>
        <button onClick={handleListarTodos}>Listar Todos</button>
      </div>
      <div className="compartilhamento">
        <button onClick={handleCompartilharWhatsapp} className="icone-botao"><FaWhatsapp size={20} /></button>
        <button onClick={handleCompartilharFacebook} className="icone-botao"><FaFacebook size={20} /></button>
        <button onClick={handleCompartilharTwitter} className="icone-botao"><FaTwitter size={20} /></button>
      </div>

      {todosResultados && todosResultados.length > 0 && (
        <div className="todos-resultados">
          <h2>Todos os resultados da Quina</h2>
          {todosResultados.map((resultado, index) => (
            <div key={index} className="resultado-container">
              <p><strong>Concurso:</strong> {resultado.concurso || 'N/A'}</p>
              <div className="bolas-container">
                {resultado.dezenas && resultado.dezenas.map((numero, index) => (
                  <div key={index} className="bola-quina">
                    {numero}
                  </div>
                ))}
              </div>
              <p><strong>Data do Sorteio:</strong> {resultado.data || 'N/A'}</p>
              {index < todosResultados.length - 1 && <hr className="linha-tracada" />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default QuinaCard;
