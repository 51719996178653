import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { TbClover } from 'react-icons/tb';
//import { TiAdjustBrightness } from 'react-icons/ti';

import './App.css';
import Menu from './Menu';
import MegaSenaCard from './MegaSenaCard';
import QuinaCard from './QuinaCard';
import LotoManiaCard from './LotoManiaCard';
import DuplaSenaCard from './DuplaSenaCard';
import Noticia from './Noticia';
import LiveSorteio from './LiveSorteio';


const firebaseConfig = {
  apiKey: "AIzaSyAz0YadUxr_uFSwpwvuNJDKTv4_Gymt9c8",
  authDomain: "lotosena-dfc6e.firebaseapp.com",
  projectId: "lotosena-dfc6e",
  storageBucket: "lotosena-dfc6e.appspot.com",
  messagingSenderId: "545821334199",
  appId: "1:545821334199:web:07d479dfeb725adc790b32",
  measurementId: "G-F4VGN25BJ5"
};

function Home() {
  return (
    <div className="warning">
      <p >Divulgamos resultados dos jogos de loterias da Caixa Econômica Federal. Em breve novidades: estatísticas, simulador, gerador de números e curiosidades. Não realizamos apostas.</p>

    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Link to="/">
          <div className="App-icon">
             <TbClover className="rotating-icon" alt="Ícone da LOTOSENA" />

          </div>
            <p>LOTOSENA</p>
          </Link>
              <div>
      <h3>Assista ao último sorteio</h3>
      <LiveSorteio videoId={'e5Bi7b4MME8'} />
    </div>
          <Noticia />
        </header>
        <Menu />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mega-sena" element={<MegaSenaCard />} />
            <Route path="/quina" element={<QuinaCard />} />
            <Route path="/lotomania" element={<LotoManiaCard />} />
            <Route path="/duplasena" element={<DuplaSenaCard />} />

          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;