// LiveSorteio.js
import React from 'react';

const LiveSorteio = ({ videoId }) => {
  return (
    <div style={{ width: '100%', position: 'relative', paddingBottom: '56.25%' }}>
      <iframe
        title="Sorteio ao vivo oterias Caixa"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default LiveSorteio;
//AIzaSyAslH9z7UmsS_dgiXmIP1QoDcsVzQjmOYs