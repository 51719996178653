// Menu.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';

const Menu = () => {
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleMenuClick = (index) => {
    setSelectedMenu(index);
  };

  return (
    <nav className="menu-container">
      <Link to="/" className={selectedMenu === 0 ? 'selected' : ''} onClick={() => handleMenuClick(0)}>Home</Link>
      <Link to="/mega-sena" className={selectedMenu === 1 ? 'selected' : ''} onClick={() => handleMenuClick(1)}>Mega-Sena</Link>

    </nav>
  );
};

export default Menu;
