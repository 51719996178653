import React, { useState, useEffect } from 'react';

const Noticia = () => {
  const [countdown, setCountdown] = useState(calculateCountdown());

  function calculateCountdown() {
    const now = new Date();
    const targetTime = new Date();
    targetTime.setHours(20, 30, 0, 0); // Define as 20h30 de hoje como o horário alvo
    if (now > targetTime) {
      // Se já passou das 20h30, define para o próximo dia
      targetTime.setDate(now.getDate() + 1);
    }
    const timeDifference = targetTime - now;
    return timeDifference;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatTime = (time) => {
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);

    return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const resultadoMegaDaVirada = ['21', '24', '33', '41', '48', '56'];


  return (
    <div>
      <h3>#ResultadoMegadaVirada2023</h3>
      <p>Concurso 2670</p>
      <div className="bolas-container">
        {resultadoMegaDaVirada.map((dezena, index) => (
          <div key={index} className="bola-mega">{dezena}</div>

        ))}
      </div>
      <p>Sorteio realizado em 31 de Dezembro de 2023</p>
    </div>
  );
};

export default Noticia;
